export const XBoxLogo = () => {
  return (
    <svg className="img-fluid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.14 149">
      <path
        d="M75.83,150c-20.27-.39-37.87-7.43-52.59-21.58-2.25-2.16-3.15-4-1.85-7.25C32,94.52,50.86,74.71,73.48,57.93c1.68-1.25,2.84-1,4.43.14,22.52,16.58,41.14,36.34,51.88,62.68,1.35,3.33.77,5.33-1.73,7.74C113.48,142.52,96.05,149.47,75.83,150Z"
        transform="translate(-1.05 -1)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M135.37,118.89C133,88.59,116,66,95.89,45.11c-2.64-2.75-2.54-4,0-6.47,7.51-7.12,15.46-13.51,25-17.87,2.47-1.14,4.19-1.2,6.43.85,26.44,24.14,30.66,69.11,9,97.13C136.21,118.85,135.93,118.81,135.37,118.89Z"
        transform="translate(-1.05 -1)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M16,119.42c-19.19-23-20.09-60.8-1.43-86.32,12.63-17.27,10.27-16.62,28.6-4.85,4.36,2.8,7.93,6.81,12,10.09,2.93,2.36,3.16,4.06.19,6.91A172,172,0,0,0,33.82,71,96.83,96.83,0,0,0,16,119.42Z"
        transform="translate(-1.05 -1)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
      <path
        d="M36.56,12C66.32-6.62,103.4,1.49,115,13.7c-13.79-1.83-24.92,4.34-35.37,12-3.09,2.26-4.82,2.5-8,.08C62,18.42,51.49,13,38.85,13.21,37.67,13.57,36.4,14.1,36.56,12Z"
        transform="translate(-1.05 -1)"
        className="active"
        fill="rgb(208, 210, 209)"
      />
    </svg>
  );
};
