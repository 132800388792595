import gsap from 'gsap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Footer from '../components/Footer';
import { usePageProvider } from '../PageContext';
import { useEffect, useRef, useState } from 'react';
import { Header } from '../components/Header';
import imagesLoaded from 'imagesloaded';
import ScrollAnimation from '../tools/ScrollAnimation';
import { FetchPageData } from '../tools/Helpers';

import HeroSideBar from '../assets/hero_sidebars.png';
import CenterDividerSvg from '../assets/center_divider_gray.svg';
const altTitle = 'Killing Floor 3 - Join the fight against unhumanity';

gsap.registerPlugin(ScrollTrigger);

interface SocialMessage {
  name: string;
  link: string;
  icon: string;
  position: string;
}

interface MenuMessage {
  title: string;
  url: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface PageState {
  content: any;
}

const Faq: React.FC = () => {
  const InitEffect = useRef(false);
  const [pageData, setPageData] = useState<PageState>({ content: {} });
  const { content, pathname } = usePageProvider();

  const headerProps: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  } = {
    header: content.header,
    social: content.social,
    isReady: content.isReady,
  };

  const footerProps: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  } = {
    footer: content.footer,
    esrb: content.esrb,
    legal: content.legal,
    isReady: content.isReady,
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const fetchData = async () => {
      try {
        const responds = await FetchPageData('page-faq', 'wp/v2/pages?slug=faq');
        if (responds && responds.content) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const startProcess = () => {
      const imgLoad = imagesLoaded('#faq-page', { background: true });
      imgLoad.on('done', () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: 'header',
          duration: '100%',
          trigger: 0.7,
          start: 'active',
        });
        ScrollController.ScrollAnim({
          element: '#content-page',
          duration: '100%',
          trigger: 0.7,
          toggle: true,
        });
      });
    };
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (process.env.NODE_ENV === 'development') {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);

  return (
    <>
      <div id="faq-page">
        <Header path={pathname} data={headerProps} />
        <div className="bars-wrapper">
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
          <img className="sidebar" src={HeroSideBar} alt={altTitle} />
        </div>
        <div id="content-page" className="container-xl">
          {pageData.content && pageData.content.title ? (
            <h1 className="inview animated">{pageData.content.title.rendered}</h1>
          ) : (
            ''
          )}
          <div className="center-bar text-center my-4">
            <img className="img-fluid" src={CenterDividerSvg} alt={altTitle} />
          </div>
          <section id="faq" className="inview animated delay-1 pt-2">
            <div className="accordion" id="faq-accordion">
              {pageData.content &&
                pageData.content.acf &&
                Array.isArray(pageData.content.acf.faqs) &&
                pageData.content.acf.faqs.map((faq: any, index: number) => (
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse${index}`}>
                        {faq.title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#faq-accordion">
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </div>
        <Footer data={footerProps} />
      </div>
    </>
  );
};

export default Faq;
