import { Component } from 'react';
import LogoAlt from '../assets/logo_alt.png';
import TripWire from '../assets/tripwire.png';
import { Link } from 'react-router-dom';
import { ScrollToView } from '../tools/Helpers';

interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface FooterProps {
  data: {
    footer: MenuMessage[];
    esrb: string;
    legal: string;
    isReady: boolean;
  };
}

export class Footer extends Component<FooterProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: false,
    };
  }

  getCookie = (cname: string) => {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };
  SetCookie = (cname: string) => {
    const d = new Date();
    d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=accept;' + expires + ';path=/';
  };
  AcceptCookie = () => {
    this.SetCookie('privacy');
    this.setState({ show: 1 });
  };
  CloseCookie = () => {
    this.setState({ show: 1 });
  };

  render() {
    const stateShow = {
      show: this.getCookie('privacy') ? 1 : 0,
    };
    const { footer, esrb, legal, isReady } = this.props.data;
    return (
      <>
        <footer>
          <div className="container-xl">
            <div className="d-md-flex justify-content-between">
              <div>
                <a href="/" className="brand-logo">
                  <img src={LogoAlt} className="img-fluid" alt="Nightfall - Home" />
                </a>
                {isReady && (
                  <nav className="d-md-flex align-items-center mt-3">
                    {footer &&
                      footer.map((nav: MenuMessage, index: number) =>
                        nav.classes && nav.classes[0] === 'scroll' ? (
                          <Link
                            key={index}
                            className="link"
                            to={nav.url}
                            onClick={() => ScrollToView(nav.attr_title ? nav.attr_title : nav.title)}>
                            {nav.title}
                          </Link>
                        ) : (
                          <Link
                            key={index}
                            className="link"
                            to={nav.url}
                            {...(nav.target ? { target: '_blank', rel: 'noopener noreferrer' } : {})}>
                            {nav.title}
                          </Link>
                        ),
                      )}
                  </nav>
                )}
                <div className="legal d-none d-md-block">{legal}</div>
              </div>
              <div className="legal-links text-end">
                <div className="legal-logos mt-3 d-flex align-items-center justify-content-between">
                  <a
                    className="item me-2"
                    href="https://www.tripwireinteractive.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="TRIPWIRE">
                    <img className="img-fluid" src={TripWire} alt="TRIPWIRE" />
                  </a>
                  <span className="item">
                    <img className="img-fluid border" src={esrb} alt="ESRB" />
                  </span>
                </div>
              </div>
            </div>
            <div className="legal mt-4 d-md-none">{legal}</div>
          </div>
        </footer>
        {!stateShow.show && (
          <div id="cookie-bar" className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    'We use cookies to ensure that we give you the best experience on our website. For more additional details read our <a href="https://www.tripwireinteractive.com/#/privacy-notice/#top" target="_blank" rel="noopener noreferrer">Cookie Policy</a>',
                }}
              />
              <button className="btn btn-theme sml ms-2" type="button" onClick={this.AcceptCookie.bind(this)}>
                OK
              </button>
            </div>
            <div id="close-cookie" className="img-fluid" onClick={this.CloseCookie.bind(this)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <g fill="#c51e1e">
                  <path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path>
                  <path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path>
                </g>
              </svg>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Footer;
