import { Component } from 'react';

import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { ScrollToView } from '../tools/Helpers';

interface HeaderProps {
  path: string;
  data: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  };
}

interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}

interface SocialMessage {
  name: string;
  link: string;
  icon: any;
  position: any;
}

export class Header extends Component<HeaderProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    const { header, social, isReady } = this.props.data;
    return (
      <header id="header">
        <div className="container-fluid d-flex justify-content-between">
          {isReady && (
            <>
              <div className="nav-wrapper d-flex">
                <a href="/" className="brand-logo">
                  <img src={Logo} className="img-fluid" alt="logo" />
                </a>
                <nav className="d-flex align-items-center">
                  {header &&
                    header.map((nav: MenuMessage, index: number) =>
                      nav.classes && nav.classes[0] === 'scroll' ? (
                        <Link
                          key={index}
                          className={`link ${this.props.path === nav.url ? 'active' : ''}`}
                          to={nav.url}
                          onClick={() => ScrollToView(nav.attr_title ? nav.attr_title : nav.title)}>
                          {nav.title}
                        </Link>
                      ) : (
                        <Link
                          key={index}
                          className={`link ${this.props.path === nav.url ? 'active' : ''}`}
                          to={nav.url}
                          {...(nav.target ? { target: '_blank', rel: 'noopener noreferrer' } : {})}>
                          {nav.title}
                        </Link>
                      ),
                    )}
                </nav>
              </div>
              <div className="social-logo">
                {social &&
                  social.map((icon: SocialMessage, k: number) => {
                    const parsePos = icon.position.split(',');
                    return (
                      <a
                        key={k}
                        href={icon.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={icon.name}
                        style={{ top: parsePos[0].trim(), left: parsePos[1].trim() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="113px" height="100px" viewBox="0 0 113 100">
                          <path
                            fillRule="evenodd"
                            fill="#c51e1e"
                            d="M84.750,0.000 L113.000,50.000 L84.750,100.000 L28.250,100.000 L0.000,50.000 L28.250,0.000 L84.750,0.000 Z"
                          />
                        </svg>
                        <img className="img-fluid" src={icon.icon.url} alt={icon.name} />
                      </a>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </header>
    );
  }
}
